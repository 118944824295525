import Igneta from './assets/profiles/igneta.svg'
import Pranjal from './assets/profiles/pranjal.svg'
import Momi from './assets/profiles/momi.svg'
import Lengdon from './assets/profiles/lengdon.svg'
import Dindayal from './assets/profiles/dindayal.svg'
import Harsha from './assets/profiles/harsha.svg'
import Souvik from './assets/profiles/souvik.svg'
import Nivetha from './assets/profiles/nivetha.svg'
import Samarjeet from './assets/profiles/samarjeet.svg'
import Varsha from './assets/profiles/varsha.svg'
import Vivek from './assets/profiles/vivek_sharma.svg'
import Nadiya from './assets/profiles/nadiya.svg'
import Zamil from './assets/profiles/zamil.svg'

export const testimonials_data = [
    {
        name: "-Igneta DSouza",
        designation: "Privacy Engineering Lead",
        testimonial: "The team at ZirohLabs is young, enthusiastic, and passionate about what they do. You will find brilliant and curiousyoung minds solving problems each day. If you are curious about solving problems, this is the team for you.",
        image: Igneta,
    },
    {
        name: "-Pranjal Bhumij",
        designation: "Product Manager",
        testimonial: "The most valuable thing we do  is giving people the control and power to decide how  their data is used or shared.",
        image: Pranjal,
    },
    {
        name: "-Momi Dutta",
        designation: "Product Manager",
        testimonial: "Your data is not meant to be sold. Our products ensure your data stays secure and private. Proud to be a part of this noble mission.",
        image: Momi,
    },
    {
        name: "-Lengdon Baruah",
        designation: "Member Technical Staff",
        testimonial: "The best perk of working at Ziroh Labs is that you are never alone, you always have help, which is a rare quality thesedays.",
        image: Lengdon,
    },
    {
        name: "-Dindayal Pandey",
        designation: "MemberTechnical Staff",
        testimonial: "At Ziroh Labs, people work on what they enjoy the most and become champions in what they do.",
        image: Dindayal,
    },
    {
        name: "-Harsha Raj Borah",
        designation: "Member Technical Staff",
        testimonial: "Digital privacy is a matter of human existence, without it, chaos reigns.Working at the labs is an excellent opportunity tocontribute and protect data privacy in this era.",
        image: Harsha,
    },
    {
        name: "-Souvik Paul",
        designation: "Member Technical Staff",
        testimonial: "We work everyday to build and improve a platform that respects your Privacy. Join us to make the internet a safer place for all.",
        image: Souvik,
    },
    {
        name: "-Nivetha Ganesh",
        designation: "Member Technical Staff",
        testimonial: "We are building products for your privacy, the way it should be.",
        image: Nivetha,
    },
    {
        name: "-Samarjeet Sahoo",
        designation: "Member Technical Staff",
        testimonial: "Ziroh Labs is the best place to learn and build a career that focuses on giving back to the community.",
        image: Samarjeet,
    },
    {
        name: "-Varsha Dahare",
        designation: "Member Technical Staff",
        testimonial: "An opportunity to work in a  fast-paced environment with the latest technology stacks.",
        image: Varsha,
    },
    {
        name: "-Vivek Sharma",
        designation: "Member Technical Staff",
        testimonial: "Ziroh Labs is a company that establishes Privacy on the Internet. Proud to be a part of it.",
        image: Vivek,
    },
    {
        name: "-Nadiya Noor Syed",
        designation: "Customer Success and Growth",
        testimonial: "The culture at Ziroh Labs is very embracing and encouraging. It's just not a usual workplace, as we all invest our time and knowledge in achieving our vision of making the internet safer. Work is more fun as we commit mistakes, learn and grow together. The accomplishment at the end of each day is the main reason I rejoice in my work.",
        image: Nadiya,

    },
    {
        name: "-Zamil Maharoof",
        designation: "Customer Success and Growth",
        testimonial: "Everything we do here at Ziroh Labs is driven by passion, the contagious kind. It motivates you to think out loud, learn and grow in any direction without any constraints.",
        image: Zamil,
    },


]