import React, { useEffect, useRef, useState } from 'react'
import styles from './form.module.scss'
import { ReactComponent as Location } from '../../../../assets/svg/location_icon.svg'
import { ReactComponent as Status } from '../../../../assets/svg/status_icon.svg'
import Submitting from '../../../../assets/gif/submitting_application.gif'
import submitted from '../../../../assets/gif/application_submitted.gif'
import useFormHook from './form.hook'
import { JOB_TIME } from '../../Constants/constants'

function Form({ onClick, jobData, }: any) {

  const {
    name, setName,
    mail, setMail,
    lastName, setLastName,
    phoneNo, setPhoneNo,
    experience, setExperience,
    noticePeriod, setNoticePeriod,
    currentCtc, setCurrentCtc,
    expectedCtc, setExpectedCtc,
    resume, setResume,
    coverLetter, setCoverLetter,
    submitModal, setSubmitModal,
    isLoading, setIsLoading,
    ResumeRef, CoverLetterRef,
    handleChangeName, handleChangeLastName,
    handleEmailChange, handlePhoneNoChange,
    handleExperience, handleCurrentCtc,
    handleExpectedCtc, handleResume,
    handleCoverLetter, handleRadioServing,
    handleRadioNotServing,
    handleNoticePeriodClick,
    submitHandler,
  } = useFormHook({ jobData })


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className={styles.formPage} >
      <div className={styles.formContainer}>
        <img src={jobData.image_url}
          className={styles.designIcon} />
        <div className={styles.formInnerContainer}>
          <div className={styles.descriptionHeaderContainer}>
            <div className={styles.descriptionLeft}>
              <p className={styles.jobRole}>{jobData.title}</p>
              <div className={styles.jobLocationStatus}>
                <p className={styles.jobLocation}><Location className={styles.locationIcon} /> Bangalore, India </p>
                <p className={styles.jobStatus}><Status className={jobData.status !== "" ? `${styles.statusIcon}` : `${styles.jobscardStatuss}`} />{jobData.status}</p>
              </div>
            </div>
            <div className={styles.descriptionRight}>
              <p className={jobData.time === JOB_TIME.INTERNSHIP ? `${styles.internship}` : (jobData.time === JOB_TIME.CONTRACT ? `${styles.contract}` : `${styles.fullTime}`)}>{jobData.time} </p>
            </div>
          </div>
          <hr></hr>
          <div className={styles.jobApplicationForm}>
            <form className={styles.form} onSubmit={submitHandler}>
              <div className={styles.formInputContainer}>
                <div className={styles.FormInputs}>
                  <label>First Name*</label>
                  <div className={styles.inputWrapper}>
                    <input type='text' required value={name} onChange={handleChangeName} />
                  </div>
                </div>
                <div className={styles.FormInputs}>
                  <label>Last Name*</label>
                  <div className={styles.inputWrapper}>
                    <input type='text' required value={lastName} onChange={handleChangeLastName} />
                  </div>
                </div>
              </div>
              <div className={styles.formInputContainer}>
                <div className={styles.FormInputs}>
                  <label> Email Id*</label>
                  <div className={styles.inputWrapper}>
                    <input type='email' required value={mail} onChange={handleEmailChange} />
                  </div>
                </div>
                <div className={styles.FormInputs}>
                  <label>Phone No*</label>
                  <div className={styles.inputWrapper}>
                    <input type='number' required value={phoneNo} onChange={handlePhoneNoChange} />
                  </div>
                </div>
              </div>
              <div className={styles.formInputContainer}>
                <div className={styles.FormInputs}>
                  <label htmlFor='experience'>Experience*</label>
                  <div className={`${styles.selectWrapper}`}>
                    <select id="experience" required value={experience} onChange={handleExperience}>
                      <option value="">Select</option>
                      <option>Fresher</option>
                      <option>0-1 Year</option>
                      <option>1-3 Years</option>
                      <option>3-5 Years</option>
                      <option>5+ Years</option>
                    </select>
                  </div>
                </div>
                <div className={styles.FormInputs}>
                  <label>Notice Period*</label>
                  <div className={`${styles.inputWrapper} ${styles.radioButtonWrapper}`}  >
                    <div className={styles.radioButton}>
                      <input type='radio' required onClick={handleRadioServing} name="NP" checked={noticePeriod.serving} />
                      <div className={styles.labelAndDays}>
                        <label>Serving</label>
                        <p className={styles.noticeDuration} data-value="Serving">{noticePeriod.serving && noticePeriod.days}</p>
                      </div>
                    </div>

                    <div className={styles.radioButton}>
                      <input type='radio' required onClick={handleRadioNotServing} name="NP" checked={noticePeriod.notServing} />

                      <div className={styles.labelAndDays}>
                        <label>Not Serving</label>
                        <p className={styles.noticeDuration} data-value="NotServing">{noticePeriod.notServing && noticePeriod.days}</p>
                      </div>

                    </div>
                  </div>

                </div>
                {noticePeriod.serving && noticePeriod.days == "" &&
                  <div className={styles.noticeValue}>
                    <p onClick={handleNoticePeriodClick} data-value="&lt;15D">&lt; 15 days</p>
                    <p onClick={handleNoticePeriodClick} data-value="&lt;30D">&lt; 30 days</p>
                    <p onClick={handleNoticePeriodClick} data-value="&lt;60D">&lt; 60 days</p>
                    <p onClick={handleNoticePeriodClick} data-value="&lt;90D">&lt; 90 days</p>

                  </div>
                }
                {noticePeriod.notServing && noticePeriod.days == "" &&
                  <div className={`${styles.noticeValue} ${styles.NotServing}`} >
                    <p onClick={handleNoticePeriodClick} data-value="IJ">Immediate Joiner</p>
                    <p onClick={handleNoticePeriodClick} data-value="15D">15 days</p>
                    <p onClick={handleNoticePeriodClick} data-value=" 30D">30 days</p>
                    <p onClick={handleNoticePeriodClick} data-value="60D">60 days</p>
                    <p onClick={handleNoticePeriodClick} data-value=" 90D">90 days</p>

                  </div>
                }
              </div>
              {jobData.time == JOB_TIME.FULL_TIME &&
                <div className={`${styles.formInputContainer}`}>
                  <div className={styles.FormInputs}>
                    <label>Current CTC*</label>
                    <div className={`${styles.CtcWrapper}`}>
                      <input type='number' required={jobData.time === JOB_TIME.FULL_TIME} value={currentCtc} onChange={handleCurrentCtc} />
                      <p className={styles.ctcText}>in LPA</p>
                    </div>
                  </div >
                  <div className={styles.FormInputs}>
                    <label>Expected CTC*</label>
                    <div className={`${styles.CtcWrapper}`}>
                      <input type='number' required={jobData.time === JOB_TIME.FULL_TIME} value={expectedCtc} onChange={handleExpectedCtc} />
                      <p className={styles.ctcText}>in LPA</p>
                    </div>
                  </div>
                </div>
              }
              <div className={styles.formInputcontainer}>
                <div className={`${styles.FormInputs} ${styles.inputFileForm}`}>
                  <label>Resume*</label>
                  <div className={`${styles.inputWrapper} ${styles.fileInput}`}>
                    {resume! ? <p className={styles.selectFile}>{resume.name}</p> : <p className={styles.selectFile}><i>Select a file</i></p>}
                    <p className={styles.browseButton}>
                      <input type='file'
                        accept='.pdf, .docx'
                        onChange={(e) => { handleResume(e) }} ref={ResumeRef} required className={styles.browseInput} />
                      Browse</p>
                  </div>
                </div>

              </div>
              <div className={styles.formInputContainer}>
                <div className={`${styles.FormInputs} ${styles.inputFileForm}`}>
                  <label>Cover Letter</label>
                  <div className={`${styles.inputWrapper} ${styles.fileInput}`}>

                    {coverLetter! ? <p className={styles.selectFile}>{coverLetter.name}</p> : <p className={styles.selectFile}><i>Select a file</i></p>}
                    <p className={styles.browseButton}>
                      <input type='file'
                        accept='.pdf, .docx'
                        onChange={(e) => { handleCoverLetter(e) }}
                        ref={CoverLetterRef}
                      />
                      Browse
                    </p>
                  </div>
                </div>
              </div>

              <div className={styles.buttonContainer}>
                <button className={styles.goBackButton} onClick={onClick}>Go Back</button>
                <button className={styles.applyButton} type='submit' disabled={isLoading}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {(submitModal.isSubmitting || submitModal.isSubmitted) &&
        <div className={styles.submitFormModal}>
          {submitModal.isSubmitting ?
            <div className={styles.SubmitForm}>
              <img src={Submitting} className={styles.SubmittedGif} />
              <p className={styles.submittingStatus}>Submitting...</p>
            </div>
            :
            <div className={styles.SubmitForm}>
              <img src={submitted} className={styles.SubmittedGif} />
              <p className={styles.submittingStatus}>Submitted!!</p>
              <p className={styles.submittingMsg}>Thank you for applying for the {jobData.title} position. We will be in touch soon.</p>
            </div>

          }
        </div>
      }

    </div>
  )
}

export default Form