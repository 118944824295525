export const ENVIRONMENT = {
    socialMediaUrls: {
        linkedin: "https://www.linkedin.com/company/zirohlabs",
        facebook: "https://www.facebook.com/zirohlabs/",
        twitter: "https://twitter.com/zirohlabs",
        instagram: "https://www.instagram.com/zirohlabs/" ,
        youtube: "https://www.youtube.com/@zirohlabs",
    },

    mailSenderUrl: "https://websiteserver-svc.vault.ziroh.com/api/v1/mailsender/careers",
    mailSenderUrlDev: "https://vault-svc1.dev.ziroh.com/websiteserver/api/v1/mailsender/careers"
}

