import {ENVIRONMENT} from '../../../environment'

export class JobDetailsService {
public async JobDetails(formData: any ){
    console.log(formData)
    try{
        const res = await fetch(process.env.NODE_ENV === 'production' ? ENVIRONMENT.mailSenderUrl : ENVIRONMENT.mailSenderUrlDev,
            {
                method:"POST",
                body: formData,
                redirect: "follow"
            }
            );
            const parserdRes = await res
            return parserdRes
        }
        catch(error){
            console.log('Error fetching Data:', error);
        }
    }
}
