import React, { useRef, useState, useEffect, HtmlHTMLAttributes } from 'react'
import styles from './careers.module.scss'
import TalktoUs from '../../assets/webp/talk_to_us.webp'
import { Helmet } from 'react-helmet'
import SectionArrows from '../../assets/png/section3_arrows.png'
import Sectiondots from '../../assets/png/section3_dots.png'
import ZirohMerch from '../../assets/webp/ziroh_merch.webp'
import ZirohMerchMobile from '../../assets/webp/ziroh_merch_mobile.webp'
import LifeAtZiroh from '../../assets/webp/life_at_ziroh.webp'
import LifeAtZirohMobile from '../../assets/webp/life_at_ziroh_mobile.webp'
import { jobs_data } from '../../JobsData'
import Carousel from 'react-multi-carousel'
import "react-multi-carousel/lib/styles.css";
import { testimonials_data } from '../../testimonials_data';
import { ReactComponent as Status } from '../../assets/svg/status_icon.svg'
import { ReactComponent as Location } from '../../assets/svg/location_icon.svg'
import { Link } from 'react-router-dom'
import { ReactComponent as TestimonialArrow } from '../../assets/svg/testimonial_arrow.svg'
import useCareerHook from './careers.hook'


function Careers() {

    const {
        currentSlide, setCurrentSlide,
        userInfo, setUserInfo,
        bottomRef, handleScrollClick,
        ChangeHandler, responsive
    } = useCareerHook();

    return (
        <div className={styles.careersContainer}>
            <div className={styles.careerSection1}>
                <div className={styles.careerSection1Child}>
                    <div className={styles.careerSection1ChildLeft}>
                        <div className={styles.sectionheading}>
                            <p className={styles.section1Header}>Join Us In Our Mission To<br>
                            </br><span>Preserve Privacy</span>
                            </p>
                        </div>
                        <div className={styles.sectionBody}>
                            <p>When you work at Ziroh
                                Labs, you make the digital world a much safer and
                                more secure place, like literally!
                                <br></br>
                                So whether you're
                                a tech geek, marketer, designer, or anything in
                                between, your skills can enhance people's lives
                                everywhere.
                                <br>
                                </br>
                                And hence, we are looking for individuals like you
                                who are eager to explore cutting-edge technologies,
                                take on challenges, and make a difference.
                            </p>
                        </div>
                        <div className={styles.ButtonContainer}>
                            <button className={styles.exploreButton} onClick={handleScrollClick}>Explore Roles</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.careerSection2}>
                <div className={styles.sectionBody2}>
                    <p className={styles.sectionHeading2}>
                        Get more done with your skills
                    </p>
                    <img src={TalktoUs} />
                    <div className={styles.sectionContents}>
                        <div className={styles.Section2Container}>
                            <div className={styles.gridcontents}>
                                <div className={styles.dotHeader}>
                                    <span className={styles.dot}></span>
                                    <p className={styles.pointHeader}> Scale Up</p>
                                </div>
                                <p className={styles.gridText}>Amplify your learning with
                                    peer motivation and timely expert guidance.</p>
                            </div>
                            <div className={styles.gridcontents}>
                                <div className={styles.dotHeader}>
                                    <span className={styles.dot}></span>
                                    <p className={styles.pointHeader}>Grow Quicker</p>
                                </div>
                                <p className={styles.gridText}>Accelerate your career growth
                                    by staying current with the latest trends that
                                    matter.</p>
                            </div>
                            <div className={styles.gridcontents}>
                                <div className={styles.dotHeader}>
                                    <span className={styles.dot}></span>
                                    <p className={styles.pointHeader}>Flexibility</p>
                                </div>
                                <p className={styles.gridText}>It's upto you to choose how
                                    you want to work as long as you keep things on
                                    track.</p>
                            </div>
                            <div className={styles.gridcontents}>
                                <div className={styles.dotHeader}>
                                    <span className={styles.dot}></span>
                                    <p className={styles.pointHeader}>Empowering</p>
                                </div>
                                <p className={styles.gridText}>Empower yourself and your
                                    colleagues by embracing an inclusive work model.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={styles.careerSection3}>
                <div className={styles.sectionBody3}>
                    <p className={styles.sectionHeading3}>Work at <span>ZIROH LABS</span></p>
                    <p className={styles.sectionSubHeader}>The best of both worlds: Work and
                        Life</p>
                    <img src={SectionArrows} className={styles.sectionArrows} />
                    <img src={Sectiondots} className={styles.sectiondots} />
                </div>

            </div>
            <div className={styles.sectionImgContainer}>
                <div className={styles.SectionImg1}>
                    <img src={ZirohMerch} className={styles.zirohMerch} />
                    <img src={ZirohMerchMobile} className={styles.zirohMerchMobile} />
                </div>
                <div className={styles.SectionImg1}>
                    <img src={LifeAtZiroh} className={styles.lifeAtZiroh} />
                    <img src={LifeAtZirohMobile} className={styles.lifeAtZirohMobile} />


                </div>
            </div>
            <div className={styles.sectionTestimonials}>
                <img src={SectionArrows} className={styles.sectionArrows} />
                <img src={SectionArrows} className={styles.sectionArrows2} />
                <img src={Sectiondots} className={styles.sectiondots} />
                <img src={Sectiondots} className={styles.sectiondots2} />
                <div className={styles.testimonialsBody}>
                    <p className={styles.testimonialsHeader}>
                        Hear From People Who Make It Possible
                    </p>
                    <div className={styles.testimonialBox}>
                        <p className={styles.userInfoTestimonial}>{userInfo.testimonial}</p>
                        <p className={styles.userInfoName}>{userInfo.name}</p>
                        <p className={styles.userInfoDesignation}>{userInfo.designation}</p>
                    </div>
                    <TestimonialArrow className={styles.testimonialArrow} />

                    <div className={styles.testimonialCarousel}>
                        <Carousel
                            afterChange={ChangeHandler}
                            showDots={false}
                            swipeable={false}
                            autoPlay={true}
                            responsive={responsive}
                            autoPlaySpeed={3000}
                            centerMode={true}
                            draggable={false}
                            arrows={false}
                            infinite={true}
                            pauseOnHover={false}
                            containerClass={styles.carouselWrapper}
                            focusOnSelect={false}
                        >
                            {testimonials_data.map((Data) => {
                                return (
                                    <div className={userInfo.image == Data.image ? `${styles.carouselActive}` : `${styles.carouselContents}`}>
                                        <img src={Data.image} className={styles.profileImg} />
                                    </div>
                                )
                            })

                            }
                        </Carousel>


                    </div>
                </div>


            </div>
            <div className={styles.jobOpeningsContainer} ref={bottomRef}>
                <div className={styles.jobOpeningHeader}>
                    <p className={styles.jobOpeningText}>Current <br></br>Openings</p>
                    <p className={styles.jobOpeningTextDesktop}>Current Openings</p>
                </div>
            </div>
            <div className={styles.jobsCardSectionContainer} >
                <div className={styles.jobCardsContainer} >
                    {jobs_data.map((data) => {
                        return (
                            <>
                                <div className={styles.jobsCard}>
                                    <div className={styles.jobsCardHeaderContainer}>
                                        <div className={styles.jobCardsHeader}>
                                            <img src={data.image_url}
                                                className={styles.DesignIcon} />
                                        </div>
                                        <div className={styles.jobsCardSubheader} >
                                            <p className={data.status == "" ? `${styles.jobscardStatusNone}` : `${styles.jobscardStatus}`}> <Status className={data.status !== "" ? `${styles.statusIcon}` : `${styles.jobscardStatuss}`} /> {data.status}</p>
                                        </div>
                                    </div>
                                    <div className={styles.jobsCardContent}>
                                        <div className={styles.JobsCardInnerContainer}>
                                            <div className={styles.Jobsection}>
                                                <div className={styles.jobsRole}>{data.title}</div>
                                                <div className={styles.jobsDetails}>
                                                    <div className={
                                                        data.time === "Internship" ? `${styles.internship}` :
                                                            (data.time === "Contract" ? `${styles.contract}` : `${styles.fullTime}`)
                                                    }>
                                                        {data.time}
                                                    </div>
                                                </div>
                                                <div className={styles.jobsLocation}>
                                                    <Location className={styles.locationIcon} />
                                                    {data.location}
                                                </div>
                                            </div>
                                            <div className={styles.jobsDescription}>
                                                {data.description}
                                            </div>
                                            <div className={styles.applyButton}>
                                                <Link to={`/apply?id=${data.job_id}`} reloadDocument className={styles.applyButtonLink}>
                                                    <button>Apply</button>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </>

                        )
                    })}
                </div>

            </div>
        </div>
    )
}

export default Careers