import React,{FC} from "react";
import styles from './layout.module.scss'
import Navbar from "../components/Navbar/navbar";
import { Outlet } from "react-router";
import Footer from "../components/Footer/footer";
 
const Layout: FC<{children: React.ReactNode }> = ({children}) => {

return (
    <div className={ styles.layoutContainer}>
        <Navbar />
        <div className={styles.main}>
            <Outlet/>
        </div>
        <Footer/>
    </div>
)
}
export default Layout