import TechIcon from '../src/assets/svg/tech_icon.svg'
import DesignIcon from '../src/assets/svg/design_icon.svg'
import ManagementIcon from '../src/assets/svg/management_icon.svg'
import { JOB_TIME, JOB_STATUS } from './pages/shared/Constants/constants';
export const jobs_data = [
  {
    job_id: "1644",
    title: "Swift Developer",
    location: "Bangalore, India",
    category: "Technology",
    time: JOB_TIME.FULL_TIME,
    status: JOB_STATUS.HIRING_ACTIVELY,
    experience: "Exp: 2+ Years",
    description: "We are seeking a talented and experienced Swift Developer to join our dynamic development team.",
    image_url: TechIcon,
    JD: `
      <h2>About us</h2>
      <br>
      <p>Ziroh Labs is a Data Security and Privacy Technology startup based in the Bay Area (California), Bangalore, Singapore, and Canberra.</p>
      <br>
      <p>We work in core Computer Science topics, and we promise you will have a lot of fun. There’s no such thing as work at Ziroh Labs. We love writing code and are looking for those of you who also enjoy the same. Compensation is, of course, at par with the industry, along with the perks of having an opportunity to read the best of the papers in computer science, discuss, present, total allowance to think radical and wild (we love failures!), and implementing tech that can change the lives of the people at large.</p>
      <br>
      <p>The scientific and technical team at Ziroh Labs includes award-winning computer scientists, cryptographers, PhDs, and developers in distributed computing, large-scale distributed storage systems, and algorithms. Ziroh Labs is a winner at Cisco Launchpad Cohort 3, Mass Challenge, Israel, Samsung Global Accelerator Program, and NASSCOM Deep Tech club members. Individuals will get opportunities to contribute in all different areas depending on the demand and requirement of the time.</p>
      <br></br>
      <h2>Job Summary</h2>
       <br>
       <p>
       We are seeking a talented and experienced Swift Developer to join our dynamic
development team. As a Swift Developer, you will play a critical role in designing,
developing, and maintaining high-quality mobile applications for our clients. You should
have a strong understanding of Swift and a passion for creating efficient, user-friendly
software.
       </p>
       <br>
       <br>
      <h2>Responsibilities</h2>
      <br>
      <ul>
        <li>Collaborate with cross-functional teams to define, design, and ship new features and
        applications.</li>
        <li>Write clean, maintainable, and efficient Swift code.</li>
        <li>Debug and resolve software defects and performance issues.</li>
        <li>Maintain up-to-date knowledge of industry trends and best practices.</li>
        <li>Participate in code reviews to ensure code quality and adherence to coding
        standards.</li>
        <li>Work closely with designers to implement UI/UX designs.</li>
        <li>Optimize applications for performance, scalability, and security.</li>
        <li>Create and maintain documentation for code and project-related tasks.</li>
        <li>Stay updated on the latest Swift and Apple platform developments.</li>
        <li>Contribute to the development of project plans and timelines.</li>
      </ul>
      <br></br>
      <h2>Requirements</h2>
      <br>
      <ul>
        <li>Elegant UI Design: Candidates should possess a keen eye for design, focusing on creating elegant,
        aesthetically pleasing user interfaces. Experience in crafting smooth, visually appealing UI elements
        and layouts that enhance user engagement and experience is essential.</li>
        <li>Expertise in implementing responsive and user-centric designs using Swift UI and UIKit(optional).</li>
        <li>Experience with creating and managing Swift packages.</li>
        <li>Proven experience in designing, developing, and maintaining high-quality SDKs.</li>
        <li>Experience in writing test cases to ensure code quality and functionality</li>
        <li>AV Foundation Expertise: Strong experience with AV Foundation for handling audio-visual media.
        This includes capabilities in managing and processing camera inputs, audio recording, video
        playback, and editing.</li>
        <li>Keychain Services: Knowledge of Keychain for secure storage of passwords, and other sensitive data.</li>
        <li>Crypto Kit: Proficiency with Crypto Kit for performing cryptographic operations like hashing, key
        generation, and encryption.</li>
        <li>Sharing data between multiple apps or between apps.</li>
        <li>Ensuring seamless user experience across different components of the same app ecosystem.</li>
        <li>Strong problem-solving abilities with a focus on algorithmic thinking.</li>
      </ul>
      <br></br>
      <h2>Location</h2>
      <br>
      <p>Electronic city, Bangalore</p>
      <br></br>
      <h2>Remuneration</h2>
      <br>
      <p>Best in the Industry, No bar for the right candidate</p>
      <br></br>
      <h2>Perks</h2>
      <br>
      <ul>
        <li>People-Friendly Culture</li>
        <li>Onsite Opportunity</li>
        <li>Flexible work culture</li>
        <li>Working on real-life challenges</li>
        <li>Group Medical Insurance (Parents/Spouse & Children’s) And many more...............</li>
      </ul>
      `,
  },
  {
    job_id: "1643",
    title: "Content Writer - Technical ",
    location: "Bangalore, India",
    category: "",
    time: JOB_TIME.FULL_TIME,
    status: JOB_STATUS.HIRING_ACTIVELY,
    experience: "Exp: 2+ Years",
    description: "We are looking for a Technical Writer to produce high-quality documentation that contributes to the overall success of our products. You will join a team of talented tech writers who work collaboratively with developers, quality engineers, product managers, and usability experts to make our products easier to use.",
    image_url: DesignIcon,
    JD: `
     
      <h2>Job Summary</h2>
       <br>
       <p>
       We are looking for a Technical Writer to produce high-quality documentation that contributes to the overall success of our products. You will join a team of talented tech writers who work collaboratively with developers, quality engineers, product managers, and usability experts to make our products easier to use.
       </p>
       <br>
       <br>
      <h2>Responsibilities</h2>
      <br>
      <ul>
        <li>Collaborate with the product technical team to gather product information to develop and publish high-quality documentation including.
          <ul>
            <li>Software Development Kit (SDK) documentation offering libraries, code samples,
            documentation, and more for the developers</li>
            <li>Application Programming Interface (API) documentation covering how to invoke
            API calls and classes, or how to include API in the code that is being developed.</li>
            <li>Release notes describing the latest software version, features released, bugs
            fixed, dependencies etc.</li>
            <li>README document with a high-level overview of the software and source code.</li>
            <li>System documentation describing the system requirements, including design
            documents and UML diagrams.</li>
          </ul>
        </li>
        <li>Research, write, and edit high-quality technical documentation that is clear, concise, and
        easy to understand.</li>
        <li>Translate technical information into user-friendly guides, articles, and documentation.</li>
        <li>Ensure all content is accurate, up-to-date, and aligned with company standards and
        guidelines.</li>
        <li>Optimise content for search engines and user engagement.</li>
      </ul>
      <br></br>
      <h2>Requirements </h2>
      <br>
      <ul>
        <li>Proven working experience in technical writing of software and SDK/API documentation</li>
        <li>Contemporary knowledge of tools and best practices when publishing technical content</li>
        <li>Ability to deliver high-quality documentation paying attention to detail</li>
        <li>Ability to quickly grasp complex technical concepts and make them easily
        understandable in text and pictures</li>
        <li>Excellent written skills in English</li>
        <li>Strong working knowledge of Microsoft Office</li>
        <li>Basic familiarity with the SDLC and software development</li>
      </ul>
      <br></br>
      <h2>About Ziroh Labs</h2>
      <br>
      <p>Ziroh Labs stands out with its advanced AI platform, Zunu Infinity, which constructs 
      cutting-edge neural network models using only CPUs, a unique feature that eliminates the 
      need for GPUs. Our innovation is driven by the creation of distributed systems and the 
      development of new mathematical approaches. This platform empowers enterprises and 
      individuals by enabling rapid model-building at significantly reduced costs and supports 
      diverse model-building and inference methodologies. It's meticulously designed to 
      automatically comply with Data Privacy and Data Residency laws, ensuring complete data 
      security and compliance for peace of mind. 
      <br>
      <br>
      Ziroh Labs is headquartered in the San Fransisco Bay Area and has offices in Bangalore and 
      Canberra. 
      <br>
      <br>
      The scientific and technical team at Ziroh Labs is a powerhouse of award-winning 
      computer scientists, cryptographers, PhDs, and developers, each with over a decade of 
      experience designing and implementing robust, large-scale distributed systems and 
      advanced cryptographic mechanisms. Ziroh Labs has earned its stripes with accolades, 
      such as the Cisco Launchpad Accelerator, Mass Challenge Israel, and Samsung Global 
      Accelerator Program. It's also a proud member of the NASSCOM Deep Tech Club. </p>
      <br>
      <br>
      <h2>Experience</h2>
      <br>
      <p>2-4 years</p>
      <br>
      <br>
      <h2>Location</h2>
      <br>
      <p>Electronic city, Bangalore</p>
      <br></br>
      <h2>Remuneration</h2>
      <br>
      <p>Best in the Industry, No bar for the right candidate</p>
      <br></br>
      <h2>Perks</h2>
      <br>
      <ul>
        <li>People-Friendly Culture</li>
        <li>Onsite Opportunity</li>
        <li>Flexible work culture</li>
        <li>Working on real-life challenges</li>
        <li>Group Medical Insurance (Parents/Spouse & Children’s) And many more...............</li>
      </ul>
      `,
  },
  // {
  //   job_id: "1631",
  //   title: "Sr.Member Technical Staff - Java",
  //   location: "Bangalore, India",
  //   category: "Technology",
  //   time: JOB_TIME.FULL_TIME,
  //   status: JOB_STATUS.HIRING_ACTIVELY,
  //   experience: "Exp: 3+ Years",
  //   description: "Strong knowledge of Java programming and basic knowledge of any front-end.",
  //   image_url: TechIcon,
  //   JD: `
  //     <h2>About us</h2>
  //     <br>
  //     <p>Ziroh Labs is a Data Security and Privacy Technology startup based in the Bay Area (California), Bangalore, Singapore, and Canberra.</p>
  //     <br>
  //     <p>We work in core Computer Science topics, and we promise you will have a lot of fun. There’s no such thing as work at Ziroh Labs. We love writing code and are looking for those of you who also enjoy the same. Compensation is, of course, at par with the industry, along with the perks of having an opportunity to read the best of the papers in computer science, discuss, present, total allowance to think radical and wild (we love failures!), and implementing tech that can change the lives of the people at large.</p>
  //     <br>
  //     <p>The scientific and technical team at Ziroh Labs includes award-winning computer scientists, cryptographers, PhDs, and developers in distributed computing, large-scale distributed storage systems, and algorithms. Ziroh Labs is a winner at Cisco Launchpad Cohort 3, Mass Challenge, Israel, Samsung Global Accelerator Program, and NASSCOM Deep Tech club members. Individuals will get opportunities to contribute in all different areas depending on the demand and requirement of the time.</p>
  //     <br></br>
  //     <h2>Roles & Responsibilities</h2>
  //     <br>
  //     <ul>
  //       <li>Contribute to the code base.</li>
  //       <li>Provide architecture and design for new features.</li>
  //       <li>Develop a project plan for the team.</li>
  //       <li>Conduct code reviews and ensure best practices.</li>
  //       <li>Participate in UX reviews.</li>
  //       <li>Provide technical solutions to the team.</li>
  //     </ul>
  //     <br></br>
  //     <h2>Skills Required</h2>
  //     <br>
  //     <ul>
  //       <li>Strong knowledge of Java programming and basic knowledge of any front-end technologies.</li>
  //       <li>Must have Strong knowledge of SOLID principles.</li>
  //       <li>Must know how to maintain and produce large codebases</li>
  //       <li>Software Architecture principles and design patterns</li>
  //       <li>Must know latest used technologies (server and client) and principles</li>
  //       <li>Understanding of Object oriented concepts, database architecture, REST APIs.</li>
  //       <li>Fair knowledge of TDD, pair programming(OR any testing framework)</li>
  //       <li>Knowledge of automation testing at different levels of software</li>
  //       <li>Ability to lead end-end software development</li>
  //       <li>Agile development</li>
  //     </ul>
  //     <br></br>
  //     <h2>Requirements</h2>
  //     <br>
  //     <ul>
  //      <li>Professional experience of 3+ Years.</li>
  //      <li>Candidates have experience in leading the team.</li>
  //      <li>Proven working experience in Java development.</li>
  //      <li>B.E/B.Tech/M.Tech degree in Computer Science, Engineering, or a related subject.</li>
  //     </ul>
  //     <br></br>
  //     <h2>Location</h2>
  //     <br>
  //     <p>Electronic city, Bangalore</p>
  //     <br></br>
  //     <h2>Remuneration</h2>
  //     <br>
  //     <p>Best in the Industry, No bar for the right candidate</p>
  //     <br></br>
  //     <h2>Perks</h2>
  //     <br>
  //     <ul>
  //       <li>People-Friendly Culture</li>
  //       <li>Onsite Opportunity</li>
  //       <li>Flexible work culture</li>
  //       <li>Working on real-life challenges</li>
  //       <li>Group Medical Insurance (Parents/Spouse & Children’s) And many more...............</li>
  //     </ul>
  //     `,
  // },

  // {
  //   job_id: "1637",
  //   title: "Digital Marketing  - AI for All",
  //   location: "Bangalore, India",
  //   category: "",
  //   time: JOB_TIME.FULL_TIME,
  //   status: JOB_STATUS.HIRING_ACTIVELY,
  //   experience: "Exp: 2+ Years",
  //   description: " We seek a highly motivated Digital Marketing Specialist/Marketer to plan, execute, and  optimise our digital marketing campaigns across various channels.",
  //   image_url: DesignIcon,
  //   JD: `

  //     <h2>Job Summary</h2>
  //      <br>
  //      <p>
  //      We seek a highly motivated Digital Marketing Specialist/Marketer to plan, execute, and 
  //      optimise our digital marketing campaigns across various channels. The ideal candidate will 
  //      be well-versed in digital marketing strategies, analytics, and content creation and 
  //      passionate about engaging audiences and driving brand growth.
  //      </p>
  //      <br>
  //      <br>
  //     <h2>Key Responsibilities </h2>
  //     <br>
  //     <ul>
  //       <li>Develop marketing strategies that align with the organisation's business goals.</li>
  //       <li>Manage and execute campaigns across various digital channels, including email, 
  //       social media, search engines, and display advertising. </li>
  //       <li>Measure and report on the performance of digital marketing campaigns and assess 
  //       against goals (ROI and KPIs) .</li>
  //       <li>Conduct market research and analyse trends to identify new opportunities and 
  //       improve campaign performance. </li>
  //       <li>Collaborate with cross-functional teams, including creative, content, and web 
  //       development, to produce engaging content and optimise user experience.</li>
  //       <li>Stay up-to-date with emerging digital marketing trends and technologies. </li>
  //     </ul>
  //     <br></br>
  //     <h2>Qualifications </h2>
  //     <br>
  //     <ul>
  //       <li>Bachelor's / Master's degree in marketing, communications, or related field, 2+ 
  //       years of experience in digital marketing or related field </li>
  //       <li>Strong understanding of digital marketing channels, including SEO, PPC, social 
  //       media, email marketing, and display advertising </li>
  //       <li>Proficient in marketing automation tools </li>
  //       <li>Excellent analytical skills and experience with web analytics tools (e.g., Google 
  //         Analytics) </li>
  //       <li>Strong project management skills </li>
  //       <li>Excellent written and verbal communication skills </li>
  //     </ul>
  //     <br></br>
  //     <h2>About  Ziroh Labs</h2>
  //     <br>
  //     <p>Ziroh Labs stands out with its advanced AI platform, Zunu Infinity, which constructs 
  //     cutting-edge neural network models using only CPUs, a unique feature that eliminates the 
  //     need for GPUs. Our innovation is driven by the creation of distributed systems and the 
  //     development of new mathematical approaches. This platform empowers enterprises and 
  //     individuals by enabling rapid model-building at significantly reduced costs and supports 
  //     diverse model-building and inference methodologies. It's meticulously designed to 
  //     automatically comply with Data Privacy and Data Residency laws, ensuring complete data 
  //     security and compliance for peace of mind. 
  //     <br>
  //     <br>
  //     Ziroh Labs is headquartered in the San Fransisco Bay Area and has offices in Bangalore and 
  //     Canberra. 
  //     <br>
  //     <br>
  //     The scientific and technical team at Ziroh Labs is a powerhouse of award-winning 
  //     computer scientists, cryptographers, PhDs, and developers, each with over a decade of 
  //     experience designing and implementing robust, large-scale distributed systems and 
  //     advanced cryptographic mechanisms. Ziroh Labs has earned its stripes with accolades, 
  //     such as the Cisco Launchpad Accelerator, Mass Challenge Israel, and Samsung Global 
  //     Accelerator Program. It's also a proud member of the NASSCOM Deep Tech Club. </p>
  //     <br>
  //     <br>
  //     <h2>Location</h2>
  //     <br>
  //     <p>Electronic city, Bangalore</p>
  //     <br></br>
  //     <h2>Remuneration</h2>
  //     <br>
  //     <p>Best in the Industry, No bar for the right candidate</p>
  //     <br></br>
  //     <h2>Perks</h2>
  //     <br>
  //     <ul>
  //       <li>People-Friendly Culture</li>
  //       <li>Onsite Opportunity</li>
  //       <li>Flexible work culture</li>
  //       <li>Working on real-life challenges</li>
  //       <li>Group Medical Insurance (Parents/Spouse & Children’s) And many more...............</li>
  //     </ul>
  //     `,
  // },
  {
    job_id: "1638",
    title: "Developer Evangelist Java, Python, AI",
    location: "Bangalore, India",
    category: "Technology",
    time: JOB_TIME.FULL_TIME,
    status: JOB_STATUS.HIRING_ACTIVELY,
    experience: "Exp: 2+ Years",
    description: "  We are seeking a passionate and dynamic Developer Evangelist to join our team. ",
    image_url: TechIcon,
    JD: `
     
      <h2>Job Summary</h2>
       <br>
       <p>
       We are seeking a passionate and dynamic Developer Evangelist to join our team. As a Developer
        Evangelist, you will serve as a bridge between our development team and the broader developer
        community. Your primary responsibility will be to engage with developers, provide technical
        support, and advocate for our products and services. You will create and deliver content that
       educates and inspires developers, helping them to build innovative applications using our
       technology. We are committed to your growth and development, o ering opportunities for
       learning and advancement within our organization.
       </p>
       <br>
       <br>
      <h2>Key Responsibilities </h2>
      <br>
      <ul>
        <li>As a Developer Evangelist, you will have the exciting opportunity to connect with other
        developers and publish content through various channels. This includes online platforms
        such as social media, blogs, articles, webinars, videos, and tutorials. You will also engage
        in relevant forums and across related communities. In addition, you will have the chance
        to connect with developers in person at global conferences, hackathons, meetups,
        coding boot camps, and other appropriate channels, expanding your network and
        knowledge in the process.</li>
        <li>Contribute to open source and evolving standards. </li>
        <li>Obsess over onboarding experience. You will proactively connect with individual
        developers who are experimenting with our platform and enterprise customers. You will
        seek to understand and remove any blockers, provide advice and guidance and assist
        with onboarding to the platform</li>
        <li> Advocate for technology and software adoption and best practices in a manner that
        non technical people can understand, understanding a variety of different use cases and
        how our technology can add business value to various industry verticals </li>
        <li>Gather insights about our products from the developer community and channel this
        information back to product and engineering teams.</li>
        <li>keep track of industry trends and develop a strong understanding of competitive
        products</li>
      </ul>
      <br></br>
      <h2>Qualifications </h2>
      <br>
      <ul>
        <li> Strong technical background with hands-on software development experience. </li>
        <li> Excellent written and verbal communication skills.</li>
        <li>Proven experience in public speaking and content creation.</li>
        <li>Active participation in developer communities and events. </li>
        <li>Passion for technology and helping developers succeed. </li>
      </ul>
      <br></br>
      <h2>About  Ziroh Labs</h2>
      <br>
      <p>Ziroh Labs stands out with its advanced AI platform, Zunu Infinity, which constructs 
      cutting-edge neural network models using only CPUs, a unique feature that eliminates the 
      need for GPUs. Our innovation is driven by the creation of distributed systems and the 
      development of new mathematical approaches. This platform empowers enterprises and 
      individuals by enabling rapid model-building at significantly reduced costs and supports 
      diverse model-building and inference methodologies. It's meticulously designed to 
      automatically comply with Data Privacy and Data Residency laws, ensuring complete data 
      security and compliance for peace of mind.
      <br> 
      <br>
      Ziroh Labs is headquartered in the San Fransisco Bay Area and has offices in Bangalore and 
      Canberra. 
      <br>
      <br>
      The scientific and technical team at Ziroh Labs is a powerhouse of award-winning 
      computer scientists, cryptographers, PhDs, and developers, each with over a decade of 
      experience designing and implementing robust, large-scale distributed systems and 
      advanced cryptographic mechanisms. Ziroh Labs has earned its stripes with accolades, 
      such as the Cisco Launchpad Accelerator, Mass Challenge Israel, and Samsung Global 
      Accelerator Program. It's also a proud member of the NASSCOM Deep Tech Club. </p>
      <br>
      <br>
      <h2>Location</h2>
      <br>
      <p>Electronic city, Bangalore</p>
      <br></br>
      <h2>Remuneration</h2>
      <br>
      <p>Best in the Industry, No bar for the right candidate</p>
      <br></br>
      <h2>Perks</h2>
      <br>
      <ul>
        <li>People-Friendly Culture</li>
        <li>Onsite Opportunity</li>
        <li>Flexible work culture</li>
        <li>Working on real-life challenges</li>
        <li>Group Medical Insurance (Parents/Spouse & Children’s) And many more...............</li>
      </ul>
      `,
  },
  {
    job_id: "1639",
    title: "Content Writer - Creative ",
    location: "Bangalore, India",
    category: "",
    time: JOB_TIME.FULL_TIME,
    status: JOB_STATUS.HIRING_ACTIVELY,
    experience: "Exp: 2+ Years",
    description: " We are looking to hire a dedicated Content Writer to create content for blogs, articles, product descriptions, social media, and the company website.",
    image_url: DesignIcon,
    JD: `
     
      <h2>Job Summary</h2>
       <br>
       <p>
       We are looking to hire a dedicated Content Writer to create content for blogs, articles, 
       product descriptions, social media, and the company website. The Content Writer's 
       responsibilities include evaluating analytics to adjust content as needed, regularly 
       updating the company's website, and promoting the company blog by pitching articles to 
       various third-party platforms. You should also be able to follow editorial guidelines when 
       creating content. 
       <br>
       <br>
       To be successful as a Content Writer, you should keep abreast of the latest SEO 
       techniques. Ultimately, a top-performing Content Writer should be able to contribute to 
       developing strategies that will increase reader engagement. 
       </p>
       <br>
       <br>
      <h2>Content Writer Responsibilities</h2>
      <br>
      <ul>
        <li>Conducting in-depth research on industry-related topics to develop original 
        content. </li>
        <li>Developing content for blogs, articles, product descriptions, social media, and the 
        company website. </li>
        <li>Assisting the marketing team in developing content for advertising campaigns. </li>
        <li>Proofreading content for errors and inconsistencies. </li>
        <li>Editing and polishing existing content to improve readability. </li>
        <li>Conducting keyword research and using SEO best practices to increase traffic to 
        the company website. </li>
        <li>Creating compelling headlines and body copy that will capture the target 
        audience's attention.  </li>
        <li>Identifying customers’ needs and recommending new content to address the 
        company's current content gaps. </li>
      </ul>
      <br></br>
      <h2>Content Writer Requirements </h2>
      <br>
      <ul>
        <li>Bachelor's degree in Communications, Marketing, English, Journalism, or related 
        field.  </li>
        <li>Proven content writing or copywriting experience. </li>
        <li>Working knowledge of content management systems.</li>
        <li>Proficient in all Microsoft Office applications.  </li>
        <li>A portfolio of published articles. </li>
        <li> Excellent writing and editing skills. </li>
        <li>The ability to work in a fast-paced environment.</li>
        <li>The ability to handle multiple projects concurrently.</li>
        <li>Effective communication skills </li>
      </ul>
      <br></br>
      <h2>About Ziroh Labs</h2>
      <br>
      <p>Ziroh Labs stands out with its advanced AI platform, Zunu Infinity, which constructs 
      cutting-edge neural network models using only CPUs, a unique feature that eliminates the 
      need for GPUs. Our innovation is driven by the creation of distributed systems and the 
      development of new mathematical approaches. This platform empowers enterprises and 
      individuals by enabling rapid model-building at significantly reduced costs and supports 
      diverse model-building and inference methodologies. It's meticulously designed to 
      automatically comply with Data Privacy and Data Residency laws, ensuring complete data 
      security and compliance for peace of mind. 
      <br>
      <br>
      Ziroh Labs is headquartered in the San Fransisco Bay Area and has offices in Bangalore and 
      Canberra. 
      <br>
      <br>
      The scientific and technical team at Ziroh Labs is a powerhouse of award-winning 
      computer scientists, cryptographers, PhDs, and developers, each with over a decade of 
      experience designing and implementing robust, large-scale distributed systems and 
      advanced cryptographic mechanisms. Ziroh Labs has earned its stripes with accolades, 
      such as the Cisco Launchpad Accelerator, Mass Challenge Israel, and Samsung Global 
      Accelerator Program. It's also a proud member of the NASSCOM Deep Tech Club. </p>
      <br>
      <br>
      <h2>Location</h2>
      <br>
      <p>Electronic city, Bangalore</p>
      <br></br>
      <h2>Remuneration</h2>
      <br>
      <p>Best in the Industry, No bar for the right candidate</p>
      <br></br>
      <h2>Perks</h2>
      <br>
      <ul>
        <li>People-Friendly Culture</li>
        <li>Onsite Opportunity</li>
        <li>Flexible work culture</li>
        <li>Working on real-life challenges</li>
        <li>Group Medical Insurance (Parents/Spouse & Children’s) And many more...............</li>
      </ul>
      `,
  },
  {
    job_id: "1640",
    title: "Marketing Intern",
    location: "Bangalore, India",
    category: "",
    time: JOB_TIME.INTERNSHIP,
    status: JOB_STATUS.HIRING_ACTIVELY,
    // experience: "Exp: 2+ Years",
    description: "  We seek a motivated and dynamic marketing intern to join our expanding marketing department.",
    image_url: DesignIcon,
    JD: `
     
      <h2>Job Summary</h2>
       <br>
       <p>
       We seek a motivated and dynamic marketing intern to
       join our expanding marketing department. Apart from having
       excellent communication skills, you should be proficient in
       multitasking and possess strong organizational abilities.
       </p>
       <br>
       <br>
      <h2>Roles and Responsibilities </h2>
      <br>
      <ul>
        <li>Conduct market research and analysis to identify
        potential clients and market trends.</li>
        <li> Assist in establishing a first line of contact with clients
        via email, phone, or other communication channels.</li>
        <li> Follow up with potential clients to schedule meetings or
        presentations.</li>
        <li> Provide administrative support to the sales and
        marketing teams as needed. </li>
        <li>Assist with data entry and customer relationship
        management (CRM) software management. </li>
        <li>Prepare reports and presentations based on
        market research findings.</li>
      </ul>
      <br></br>
      <h2> Requirements </h2>
      <br>
      <ul>
        <li> Currently pursuing a Bachelors or Master’s degree in Business,
        Marketing, or a related field. </li>
        <li>Strong communication and interpersonal skills.</li>
        <li>Proficient in Microsoft Office Suite/ Google Docs </li>
        <li>Knowledge of CRM software is a plus. </li>
        <li>Strong organizational and time management skills. </li>
        <li>Ability to work independently and as part of a team.</li>
      </ul>
      <br></br>
      <h2>About Ziroh Labs</h2>
      <br>
      <p> Ziroh Labs is a Data Security and Privacy Technology startup based in the Bay Are
      (California), Bangalore, Singapore, and Canberra. We focus on cutting-edge
      encryption techniques, which include Fully Homomorphic Encryption. The scientific
      and technical team at Ziroh Labs includes award-winning computer scientists,
      cryptographers, PhDs, and developers in distributed computing,large-scale
      distributed storage systems, and algorithms.
      <br>
      <br>
      Ziroh Labs is a winner at Cisco Launchpad Cohort 3, Mass Challenge, Israel, Samsung
      Global Accelerator Program, and is a member of the NASSCOM Deep Tech club. 
      </p>
      <br>
      <br>
      <h2>Location</h2>
      <br>
      <p>Electronic city, Bangalore</p>
      <br></br>
      <h2>Tenure</h2>
      <br>
      <p>2 months</p>
      <br>
      <br>
      <h2>Stipend</h2>
      <br>
      <p> 20k/Month</p>
      <br></br>
      <h2>Perks</h2>
      <br>
      <ul>
        <li>People-Friendly Culture</li>
        <li>Onsite Opportunity</li>
        <li>Flexible work culture</li>
        <li>Working on real-life challenges</li>
        <li>Group Medical Insurance (Parents/Spouse & Children’s) And many more...............</li>
      </ul>
      `,
  },
  // {
  //   job_id: "1641",
  //   title: "Video Spokesperson (Female) - AI for All. ",
  //   location: "Bangalore, India (Hybrid)",
  //   category: "",
  //   time: JOB_TIME.CONTRACT,
  //   status: JOB_STATUS.HIRING_ACTIVELY,
  //   experience: "Exp: 2+ Years",
  //   description: " We seek a charismatic and articulate Video Spokesperson to represent our idea in tutorial video productions.",
  //   image_url: DesignIcon,
  //   JD: `
  //     <h2>Job Summary</h2>
  //     <br>
  //     <p>
  //     We seek a charismatic and articulate Video Spokesperson to represent our idea in tutorial video productions. The ideal candidate should have a natural on-camera presence, exceptional communication skills, and the ability to convey information in a clear, engaging manner. As a Video Spokesperson, you will be the face and voice of our company, delivering scripted and unscripted content for promotional, educational, and informational videos across multiple platforms.
  //     </p>
  //     <br>
  //     <br>
  //     <h2>Key Responsibilities:</h2>
  //     <br>
  //     <ul>
  //       <li><strong>On-Camera Presentation</strong>: Serve as the primary on-camera talent for all company video productions, including tutorials, interviews, and product demonstrations. Maintain a professional and engaging demeanor while presenting information clearly and accurately.</li>
  //       <li><strong>Script Reading and Delivery</strong>: Read from scripts or teleprompters with confidence and natural delivery. Memorize key points and information to deliver without reliance on scripts when required.</li>    
  //       <li><strong>Content Collaboration</strong>: Work closely with the tech, marketing, content, and production teams to understand the objectives and key messages for each video project. Provide input and feedback on scripts and storyboards to enhance the final output.</li>      
  //       <li><strong>Brand Representation</strong>: Embody the company’s brand values and voice in all video communications. Ensure consistency in messaging and tone across different video formats and platforms.</li>
  //       <li><strong>Video Production Assitance</strong>: Participate in rehearsals and production meetings to ensure smooth execution of video shoots.</li>
  //       <li><strong>Continuous Improvement</strong>: Stay updated with industry trends, video production techniques, and best practices in on-camera presentations.</li>
  //     </ul>
  //     <br>
  //     <br>
  //     <h2>Qualifications:</h2>
  //     <br>
  //     <ul>
  //       <li>Some experience as an on-camera talent or in a similar role (optional).</li>
  //       <li>Excellent verbal communication and public speaking skills.</li>
  //       <li>Ability to work collaboratively with a creative team.</li>
  //     </ul>
  //     <br>
  //     <br>
  //     <h2>Working Conditions:</h2>
  //     <br>
  //     <ul>
  //       <li>The role may require occasional travel to the office premises during recording sessions only.</li>  
  //       <li>Flexible hours may be necessary to accommodate schedules.</li>
  //       <li>This is an exciting opportunity to play a crucial role in our content creation efforts and represent a dynamic brand in the digital space. If you have a passion for storytelling and a talent for engaging audiences, we encourage you to apply.</li>
  //     </ul>
  //     <br>
  //     <br>
  //     <h2>Benefits:</h2>
  //     <br>
  //     <ul>
  //       <li>Payment: ₹5000/- per video. 
  //         <br>
  //         Total script: 60, <strong>Total project cost: ₹3,00,000</strong>
  //       </li>
  //       <li>Certificate</li>
  //       <li>Collaborate with one of the best tech teams in Bangalore, India.</li>
  //       <li>Gain comprehensive insights into one of the next groundbreaking products.</li>
  //     </ul>
  //     <br>
  //     <br>
  //     <h2>About Zunu Infinity:</h2>
  //     <br>
  //     <p>Zunu Infinity is the <strong>world's largest AI supercomputer</strong> that builds next-gen AI models using privacy-preserving techniques and <strong>distributed CPU-based processing</strong>. It ensures compliance with privacy laws, <strong>reduces carbon emissions</strong>, and <strong>provides powerful AI capabilities</strong> to organizations and individuals without letting them invest in expensive GPUs or having data privacy risks.</p>
  //     <br>
  //     <br>
  //     <h2>About Ziroh labs:</h2>
  //     <br>
  //     <p><a href="https://ziroh.com" target="_blank">https://ziroh.com</a>
  //       <br>
  //       Ziroh Labs stands out with its advanced AI platform, Zunu Infinity, which constructs cutting-edge neural network models using only CPUs, a unique feature that eliminates the need for GPUs. Our innovation is driven by the creation of distributed systems and the development of new mathematical approaches. This platform empowers enterprises and individuals by enabling rapid model-building at significantly reduced costs and supports diverse model-building and inference methodologies. It's meticulously designed to automatically comply with Data Privacy and Data Residency laws, ensuring complete data security and compliance for peace of mind.
  //       <br>
  //       <br>
  //       Ziroh Labs is headquartered in the San Fransisco Bay Area and has offices in Bangalore and Canberra. The scientific and technical team at Ziroh Labs is a powerhouse of award-winning computer scientists, cryptographers, PhDs, and developers, each with over a decade of experience designing and implementing robust, large-scale distributed systems and advanced cryptographic mechanisms. Ziroh Labs has earned its stripes with accolades, such as the Cisco Launchpad Accelerator, Mass Challenge Israel, and Samsung Global Accelerator Program. It's also a proud member of the NASSCOM Deep Tech Club.
  //     </p>
  //     `,
  // },
  // {
  //   job_id: "1642",
  //   title: "Video Spokesperson (Male) - AI for All. ",
  //   location: "Bangalore, India (Hybrid)",
  //   category: "",
  //   time: JOB_TIME.CONTRACT,
  //   status: JOB_STATUS.HIRING_ACTIVELY,
  //   experience: "Exp: 2+ Years",
  //   description: " We seek a charismatic and articulate Video Spokesperson to represent our idea in tutorial video productions.",
  //   image_url: DesignIcon,
  //   JD: `
  //     <h2>Job Summary</h2>
  //     <br>
  //     <p>
  //     We seek a charismatic and articulate Video Spokesperson to represent our idea in tutorial video productions. The ideal candidate should have a natural on-camera presence, exceptional communication skills, and the ability to convey information in a clear, engaging manner. As a Video Spokesperson, you will be the face and voice of our company, delivering scripted and unscripted content for promotional, educational, and informational videos across multiple platforms.
  //     </p>
  //     <br>
  //     <br>
  //     <h2>Key Responsibilities:</h2>
  //     <br>
  //     <ul>
  //       <li><strong>On-Camera Presentation</strong>: Serve as the primary on-camera talent for all company video productions, including tutorials, interviews, and product demonstrations. Maintain a professional and engaging demeanor while presenting information clearly and accurately.</li>
  //       <li><strong>Script Reading and Delivery</strong>: Read from scripts or teleprompters with confidence and natural delivery. Memorize key points and information to deliver without reliance on scripts when required.</li>    
  //       <li><strong>Content Collaboration</strong>: Work closely with the tech, marketing, content, and production teams to understand the objectives and key messages for each video project. Provide input and feedback on scripts and storyboards to enhance the final output.</li>      
  //       <li><strong>Brand Representation</strong>: Embody the company’s brand values and voice in all video communications. Ensure consistency in messaging and tone across different video formats and platforms.</li>
  //       <li><strong>Video Production Assitance</strong>: Participate in rehearsals and production meetings to ensure smooth execution of video shoots.</li>
  //       <li><strong>Continuous Improvement</strong>: Stay updated with industry trends, video production techniques, and best practices in on-camera presentations.</li>
  //     </ul>
  //     <br>
  //     <br>
  //     <h2>Qualifications:</h2>
  //     <br>
  //     <ul>
  //       <li>Some experience as an on-camera talent or in a similar role (optional).</li>
  //       <li>Excellent verbal communication and public speaking skills.</li>
  //       <li>Ability to work collaboratively with a creative team.</li>
  //     </ul>
  //     <br>
  //     <br>
  //     <h2>Working Conditions:</h2>
  //     <br>
  //     <ul>
  //       <li>The role may require occasional travel to the office premises during recording sessions only.</li>  
  //       <li>Flexible hours may be necessary to accommodate schedules.</li>
  //       <li>This is an exciting opportunity to play a crucial role in our content creation efforts and represent a dynamic brand in the digital space. If you have a passion for storytelling and a talent for engaging audiences, we encourage you to apply.</li>
  //     </ul>
  //     <br>
  //     <br>
  //     <h2>Benefits:</h2>
  //     <br>
  //     <ul>
  //       <li>Payment: ₹5000/- per video. 
  //         <br>
  //         Total script: 60, <strong>Total project cost: ₹3,00,000</strong>
  //       </li>
  //       <li>Certificate</li>
  //       <li>Collaborate with one of the best tech teams in Bangalore, India.</li>
  //       <li>Gain comprehensive insights into one of the next groundbreaking products.</li>
  //     </ul>
  //     <br>
  //     <br>
  //     <h2>About Zunu Infinity:</h2>
  //     <br>
  //     <p>Zunu Infinity is the <strong>world's largest AI supercomputer</strong> that builds next-gen AI models using privacy-preserving techniques and <strong>distributed CPU-based processing</strong>. It ensures compliance with privacy laws, <strong>reduces carbon emissions</strong>, and <strong>provides powerful AI capabilities</strong> to organizations and individuals without letting them invest in expensive GPUs or having data privacy risks.</p>
  //     <br>
  //     <br>
  //     <h2>About Ziroh labs:</h2>
  //     <br>
  //     <p><a href="https://ziroh.com" target="_blank">https://ziroh.com</a>
  //       <br>
  //       Ziroh Labs stands out with its advanced AI platform, Zunu Infinity, which constructs cutting-edge neural network models using only CPUs, a unique feature that eliminates the need for GPUs. Our innovation is driven by the creation of distributed systems and the development of new mathematical approaches. This platform empowers enterprises and individuals by enabling rapid model-building at significantly reduced costs and supports diverse model-building and inference methodologies. It's meticulously designed to automatically comply with Data Privacy and Data Residency laws, ensuring complete data security and compliance for peace of mind.
  //       <br>
  //       <br>
  //       Ziroh Labs is headquartered in the San Fransisco Bay Area and has offices in Bangalore and Canberra. The scientific and technical team at Ziroh Labs is a powerhouse of award-winning computer scientists, cryptographers, PhDs, and developers, each with over a decade of experience designing and implementing robust, large-scale distributed systems and advanced cryptographic mechanisms. Ziroh Labs has earned its stripes with accolades, such as the Cisco Launchpad Accelerator, Mass Challenge Israel, and Samsung Global Accelerator Program. It's also a proud member of the NASSCOM Deep Tech Club.
  //     </p>
  //     `,
  // },

];