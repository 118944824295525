import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import styles from './apply.module.scss'
import parse from 'html-react-parser'
import { ReactComponent as Location } from '../../../../../assets/svg/location_icon.svg'
import { ReactComponent as Status } from '../../../../../assets/svg/status_icon.svg'
import { JOB_TIME } from '../../../Constants/constants'

function Apply({ onClick, jobData }: any) {

  function JDArticle({ contents }: { contents: string }) {
    return (
      <div>
        {parse(contents)}
      </div>
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className={styles.applyJDPage}>
        <div className={styles.applyJDContainer}>
          <div className={styles.applyDescriptionContainer}>
            <img src={jobData.image_url}
              className={styles.designIcon} />
            <div className={styles.descriptionInnerContainer}>
              <div className={styles.descriptionHeaderContainer}>
                <div className={styles.descriptionLeft}>
                  <p className={styles.jobRole}>{jobData.title}</p>
                  <p className={styles.jobLocation}>{jobData.location} </p>
                  <p className={styles.jobStatus}><Status className={jobData.status !== "" ? `${styles.statusIcon}` : `${styles.jobscardStatuss}`} /> {jobData.status}</p>
                </div>
                <div className={styles.descriptionRight}>
                  <p className={ jobData.time === JOB_TIME.INTERNSHIP ? `${styles.internship}` : (jobData.time === JOB_TIME.CONTRACT ? `${styles.contract}` : `${styles.fullTime}`)}>{jobData.time} </p>
                </div>
              </div>
              <div className={styles.descriptionBody}>
                <JDArticle contents={jobData.JD} />
              </div>


              <div className={styles.buttonContainer}>
                <Link to={"/"} reloadDocument>
                  <button className={styles.goBackButton}>Go Back</button>
                </Link>
                <button className={styles.applyButton} onClick={onClick}>Apply</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>

  )
}

export default Apply