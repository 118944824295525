import React, { useState, useRef, useEffect } from 'react'
import { toast } from 'react-toastify'
import { jobDetailsService } from '../../../../App.registration';
import { jobs_data } from '../../../../JobsData';

const useFormHook = ({ jobData }: any) => {
  const [name, setName] = useState('');
  const [mail, setMail] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNo, setPhoneNo] = useState('')
  const [experience, setExperience] = useState('')
  const [currentCtc, setCurrentCtc] = useState('')
  const [expectedCtc, setExpectedCtc] = useState('')
  const [noticePeriod, setNoticePeriod] = useState<any>({
    days: "",
    serving: false,
    notServing: false,
  })
  const [resume, setResume] = useState<any>(null);
  const [coverLetter, setCoverLetter] = useState<any>(null)
  const [submitModal, setSubmitModal] = useState<any>({
    isSubmitting: false,
    isSubmitted: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const ResumeRef = useRef<HTMLInputElement>(null);
  const CoverLetterRef = useRef<HTMLInputElement>(null);


  function handleChangeName(e: any) {
    setName(e.target.value);
  }
  function handleChangeLastName(e: any) {
    setLastName(e.target.value)
  }
  function handleEmailChange(e: any) {
    setMail(e.target.value);
  }

  function handlePhoneNoChange(e: any) {
    setPhoneNo(e.target.value)
  }
  function handleExperience(e: any) {
    setExperience(e.target.value);
  }

  function handleCurrentCtc(e: any) {
      setCurrentCtc(e.target.value); 
  }
  function handleExpectedCtc(e: any) {
      setExpectedCtc(e.target.value) 
  }

  function handleResume(e: any) {
    console.log(e.target.files[0]);
    setResume(e.target.files[0])
  }
  function handleCoverLetter(e: any) {
    setCoverLetter(e.target.files[0]);
  }


  function handleRadioServing(e: any) {
    setNoticePeriod({
      serving: true,
      notServing: false,
      days: e.target.innerText
    })

  }

  function handleRadioNotServing(e: any) {
    setNoticePeriod({
      serving: false,
      notServing: true,
      days: e.target.innerText
    })

  }

  function handleNoticePeriodClick(e: any) {
    if (window.screen.availWidth < 550) {
      setNoticePeriod({
        ...noticePeriod,
        days: e.target.getAttribute("data-value")
      })
    }
    else {
      setNoticePeriod({
        ...noticePeriod,
        days: e.target.innerText

      })
    }


  }

  const submitHandler = (e: any) => {

    e.preventDefault();
    if (!noticePeriod.days && ((noticePeriod.serving) || (noticePeriod.notServing))) {
      setSubmitModal({
        ...submitModal,
        isSubmitting: false,
      })
      toast.warn("please choose Notice Period Days")
    }

    else {
      console.log(name)
      console.log(lastName)
      console.log(mail)
      console.log(phoneNo)
      console.log(experience)
      console.log((noticePeriod.serving && "Serving") || (noticePeriod.notServing && "NotServing"), noticePeriod.days)
      console.log(!currentCtc && "N/A" || currentCtc)
      console.log(!expectedCtc && "N/A" || expectedCtc)
      console.log(resume)
      console.log(coverLetter);
      setIsLoading(true)

      setSubmitModal({
        isSubmitting: true,
        isSubmitted: false,
      })

      jobDetails(name, lastName, mail, phoneNo, experience, `${(noticePeriod.serving ? "Serving" : "Not Serving")}  (${noticePeriod.days.replace("IJ", "Immediate Joiner").replace("D", "days")})`, resume, coverLetter, `${currentCtc && currentCtc}`, `${expectedCtc && expectedCtc}`)
    }
  }



  function clearForm() {
    setName("");
    setLastName("");
    setMail("");
    setPhoneNo("");
    setExperience("");
    setNoticePeriod({
      days: "",
      serving: false,
      notServing: false,
    });
    setCurrentCtc("");
    setExpectedCtc("");
    setResume(null);
    setCoverLetter(null);


    if (ResumeRef.current) {
      ResumeRef.current.value = "";
    }

    if (CoverLetterRef.current) {
      CoverLetterRef.current.value = "";
    }
  }


  async function jobDetails(name: string, lastName: string, mail: string, phoneNo: string, experience: string, noticePeriod: string, resume: File, coverLetter?: File, currentCtc?: string, expectedCtc?: string,) {
    var formData = new FormData();
    formData.append(
      "jsonData",
      `{\n    \"subject\": \"Job Application: ${jobData.title}\",\n    \"body\": \"Job Title: ${jobData.title} <br> Job Type: ${jobData.time} <br> First Name: ${name} <br> Last Name: ${lastName} <br> Email: ${mail} <br> Phone: ${phoneNo} <br> Experience: ${experience} <br> Notice period: ${noticePeriod}   ${currentCtc && `<br> Current CTC: ${currentCtc}`}  ${expectedCtc && ` <br> Expected CTC: ${expectedCtc}`}<br>\"\n}`
    )

    formData.append("file", resume)
    if (coverLetter) {
      formData.append("file", coverLetter)
    }


    try {

      const response = await jobDetailsService.JobDetails(formData);

      if (response?.status == 200) {

        setSubmitModal({
          isSubmitting: false,
          isSubmitted: true,
        })

        clearForm();
        setTimeout(() => {
          setSubmitModal({
            ...submitModal,
            isSubmitted: false
          })
          toast.success(`Application Successfully Submitted!`)
        }, 1500)

      }
       else {
        setSubmitModal({
          isSubmitting: false,
          isSubmitted: false
        })
        toast.error("Failed to Submit Application")
      }

    }
    catch (error) {
      console.log(error);

    }
    finally {
      setIsLoading(false)
    }
  }
  return {
    name, setName,
    mail, setMail,
    lastName, setLastName,
    phoneNo, setPhoneNo,
    experience, setExperience,
    noticePeriod, setNoticePeriod,
    currentCtc, setCurrentCtc,
    expectedCtc, setExpectedCtc,
    resume, setResume,
    coverLetter, setCoverLetter,
    submitModal, setSubmitModal,
    isLoading, setIsLoading,
    ResumeRef, CoverLetterRef,
    handleChangeName, handleChangeLastName,
    handleEmailChange, handlePhoneNoChange,
    handleExperience, handleCurrentCtc,
    handleExpectedCtc, handleResume,
    handleCoverLetter, handleRadioServing,
    handleRadioNotServing,
    handleNoticePeriodClick,
    clearForm,
    submitHandler
  }
}

export default useFormHook