import React,{useState, useRef,useEffect} from "react";
import { testimonials_data } from "../../testimonials_data";

const useCareerHook = () => {
    const [currentSlide, setCurrentSlide] = useState(1);
    const [userInfo, setUserInfo] = useState<any>(
        {
            name: "name",
            designation: "designation",
            testimonial: "testimonial",
            image: "image",
            status: "status"
        });
    const bottomRef = useRef<any>(null);

    function handleScrollClick() {
        bottomRef.current.scrollIntoView({ behavior: "smooth" })
    }


    const ChangeHandler = () => {
        if (currentSlide < testimonials_data.length - 1) {
            setCurrentSlide(currentSlide + 1);

        }
        else {
            setCurrentSlide(0);
        }
    };
    useEffect(() => {
        setUserInfo(testimonials_data[currentSlide]);
    }, [currentSlide])

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1700 },
            items: 3,
            slidesToSlide: 1
        },
        desktopLarge: {
            breakpoint: { max: 1700, min: 1400 },
            items: 3,
            slidesToSlide: 1
        },
        desktop: {
            breakpoint: { max: 1400, min: 1000 },
            items: 3,
            slidesToSlide: 1
        },
        tabletLarge: {
            breakpoint: { max: 1000, min: 730 },
            items: 3,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 730, min: 550 },
            items: 3,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 550, min: 0 },
            items: 3,
            slidesToSlide: 1
        }
    };

    return{
        currentSlide, setCurrentSlide,
        userInfo, setUserInfo,
        bottomRef,handleScrollClick,
        ChangeHandler,responsive

    }

}
export default useCareerHook