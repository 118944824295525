import React from 'react'
import styles from './navbar.module.scss'
import { ReactComponent as ZunuColoredLogo } from '../../assets/svg/zunu-logo-colored.svg'
import { Link } from 'react-router-dom'

function Navbar() {
  return (
    <div className={styles.navbarContainer}>
      <div className={styles.navbarMenu}>
        <Link reloadDocument to="/">
          <ZunuColoredLogo className={styles.logo} />
        </Link>

      </div>




    </div>
  )
}

export default Navbar