import React, { useEffect, useRef, useState } from 'react'
import styles from './applyform.module.scss'
import Apply from '../shared/Components/Apply/Apply/apply'
import Form from '../shared/Components/Form/form'
import { Link, useSearchParams } from 'react-router-dom'
import { jobs_data } from '../../JobsData'
import useApplyFormHook from './applyForm.hook'


// interface JDprops {
//   JD: string,
//   Title: string
//   location: string,
//   status: string,
//   Time: string,
//   image_url : string

// }

function ApplyForm() {
  const {
    applypage, setApplyPage,
    formPage, setFormPage,
    jobData, setJobData,
    handleApplyPage,handleGoback
  } = useApplyFormHook()
 
  return (

    <div className={styles.applyFormContainer}>
      {applypage &&
        <Apply jobData={jobData} onClick={handleApplyPage} />
      }
      {formPage &&
        <Form jobData={jobData} onClick={handleGoback} />
      }
    </div>
  )
}

export default ApplyForm