import React from 'react';
import './App.scss'
import Routing from './App.routing';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <Routing/>
      <ToastContainer
        position={ 'bottom-left'}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // @ts-ignore
        theme={"dark"}
      />
    </div>
  );
}

export default App;
