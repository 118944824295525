import React,{useState,useEffect} from "react";
import { useSearchParams } from "react-router-dom";
import { jobs_data } from "../../JobsData";

const useApplyFormHook = () =>{
    const [applypage, setApplyPage] = useState(true);
    const [formPage, setFormPage] = useState(false);
    const [jobData, setJobData] = useState<any>({
      JD: "Jd",
      Title: "title",
      location: "location",
      status: "status",
      Time: "time",
      image_url: "image"
    })
  
    const [searchParams] = useSearchParams();
  
    useEffect(() => {
  
      const job_id = searchParams.get("id")
      console.log(job_id);
  
      const job = jobs_data.find(
        (obj) => obj.job_id === job_id
  
      )
      console.log(job)
  
      setJobData(job);
  
  
    }, [])
  
  
  
    function handleApplyPage() {
      setApplyPage(false)
      setFormPage(true)
    }
  
    function handleGoback() {
      setApplyPage(true)
      setFormPage(false)
    }
    return{
        applypage, setApplyPage,
        formPage, setFormPage,
        jobData, setJobData,
        handleApplyPage,handleGoback

    }
}
export default useApplyFormHook